import { router } from '../routes';
import { pinia } from '../stores/pinia';
import { logout } from '../util/logout';

const openPages = ['/affiliate', '/invite', '/test', '/onboarding', '/shared', '/forbidden'];
const sharedAllowedPages = ['/gallery', '/editor', '/settings'];

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore(pinia);
  const { token } = to.query;
  if (token) {
    await authStore.init(token as string, true);
    return router.push({
      path: to.path,
      query: {
        ...to.query,
        token: undefined,
      },
      hash: to.hash,
      // params: to.params,
      replace: true,
    });
  }
  if (authStore.jwt && !authStore.user) {
    try {
      await authStore.tryFetchUser();
    } catch (e) {
      console.error(e);
    }
  }
  if (openPages.some((path) => to.path.startsWith(path))) {
    return;
  }
  const teamStore = useTeamStore(pinia);
  if (authStore.user) {
    if (
      import.meta.env.V_ENV === 'STAGING' &&
      !(
        authStore.user.permissions.includes('bluepic.admin') ||
        authStore.user.permissions.includes('env.staging') ||
        authStore.user.shared
      )
    ) {
      return router.push('/forbidden');
    }
    if (authStore.user.shared) {
      if (!sharedAllowedPages.some((path) => to.path.startsWith(path))) {
        return router.push('/');
      }
    }
    if (authStore.user.locked) {
      logout();
    }
    teamStore.watchTeam();
  } else {
    return router.push({
      path: '/onboarding',
      force: true,
    });
  }
});
